<template>
  <StdPageContainer type="list">
    <template slot="title">
      <!-- NOTE: 标题栏: 开发人员根据业务调整 -->
      <StdPageTitle title="领取用户">
        <div slot="content">数据更新频率：10秒，建议任务结束后查看完成数据</div>
      </StdPageTitle>
    </template>

    <!-- 内容区 -->
    <template slot="content">
      <!-- 这块内容在 无Tab 和 有tab 页面都使用，所有单独抽离-->
      <ReceiptDetails
        style="min-height: 600px"
        source="admin"
        :redPacketId="$route.params.redpacket_id"
      />
    </template>
  </StdPageContainer>
</template>

<script>
  import { StdPageContainer, StdPageTitle } from '@vhcl/ui-console';
  import { ReceiptDetails } from '@vhcl/red-packet';
  export default {
    components: {
      ReceiptDetails
    },
    methods: {
      handleSearch() {}
    }
  };
</script>
